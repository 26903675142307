<template>
    <div class="spplied">
        <div class="spplied-content">
            <div class="row justify-content-center">
                <div class="col-auto spplied-content-card">

                    <div class="card spplied-content-card-container">
                        <div class="card-body row align-items-center justify-content-center h-100">
                            <div class="col-12 col-lg-6 mb-4 mb-lg-0">
                                <div class="mb-5">
                                    <p class="mb-4 text-center">
                                        <img src="@/assets/images/tendencys.svg" class="img-100" alt="">
                                    </p>
                                    <h3 class="text-center f-w-600 text-center" v-text="'Accounts by Tendencys'">
                                    </h3>
                                </div>
                                <p class="f-18 mb-5 text-center" v-html="$t('messages.tendencysMessage')"></p>
                                <p class="f-16" v-html="$t('messages.tendencysLanding')">
                                </p>
                            </div>
                            <div class="col-12 col-lg-6 h-100">
                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card w-100 platform-card">
                                            <div class="card-body">
                                                <div class="row h-100">
                                                    <div class="col-auto">
                                                        <img src="@/assets/images/ecart-ecommerce.png"
                                                            class="img-cover-100" alt="">
                                                    </div>
                                                    <div class="col h-100 d-flex flex-column justify-content-between">
                                                        <h4> <strong>Ecart Seller</strong> </h4>
                                                        <p v-text="$t('messages.ecartSeller')"></p>
                                                    </div>
                                                </div>
                                                <p class="text-right">
                                                    <router-link :to="fnSellerLink('login')" v-text="$t('general.login')">
                                                    </router-link> |
                                                    <router-link :to="fnSellerLink('signup')" v-text="$t('header.signup')">
                                                    </router-link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="card w-100 platform-card">
                                            <div class="card-body">

                                                <div class="row h-100">
                                                    <div class="col-auto">
                                                        <img src="@/assets/images/ecart-pay.svg"
                                                            class="img-contain-100 px-3 border" alt="">
                                                    </div>
                                                    <div class="col h-100 d-flex flex-column justify-content-between">
                                                        <h4> <strong>Ecart Pay</strong> </h4>
                                                        <p v-text="$t('messages.ecartPay')"></p>
                                                    </div>
                                                </div>
                                                <p class="text-right">
                                                    <router-link :to="fnPayLink('login')" v-text="$t('general.login')">
                                                    </router-link> |
                                                    <router-link :to="fnPayLink('signup')" v-text="$t('header.signup')">
                                                    </router-link>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <router-link to="/accounts/login"> Login</router-link> /
                                <router-link to="/accounts/login"> Signup</router-link>

                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam rem sequi dolorem
                                    error
                                    assumenda rerum impedit cupiditate, quos vitae quasi deserunt, alias ratione
                                    corporis sint
                                    qui commodi saepe perspiciatis fugit.
                                </p> -->
                        </div>
                    </div>

                    <div class="row justify-content-between ">
                        <div class="col-auto col-md-auto">
                            <Languages variant='link text-muted p-0' />
                        </div>
                        <div class="col-auto col-md-auto">
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <p>
                                        <router-link class="text-muted f-w-500" to="/accounts/help"
                                            v-text="$t('general.help')">
                                        </router-link>
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        <router-link class="text-muted f-w-500" to="/accounts/privacy"
                                            v-text="$t('general.privacy')">
                                        </router-link>
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        <router-link class="text-muted f-w-500" to="/accounts/conditions"
                                            v-text="$t('general.conditions')"> </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import Languages from "@/components/Languages";
    export default {
        components: {
            Languages
        },
        methods: {
            fnSellerLink(page) {
                return `/accounts/${page}?site_id=` + process.env.VUE_APP_ACCOUNTS_ECART_SELLER;
            },
            fnPayLink(page) {
                return `/accounts/${page}?site_id=` + process.env.VUE_APP_ACCOUNTS_ECART_PAY;
            }
        }

    }
</script>


<style lang="scss">
    .spplied {
        background-color: black;
        padding-top: 25vh;

        .spplied-content {
            min-height: 75vh;
            background-color: white;

            .spplied-content-card {

                .spplied-content-card-container {
                    min-height: 600px;
                    min-width: 600;
                    max-width: 1200px;
                }

                transform: translateY(-10vh);

                @media only screen and (max-width: 990px) {
                    .spplied-content-card-container {
                        min-height: 500px;
                        width: auto;
                    }

                    transform: translateY(-15vh);
                }
            }

            @media only screen and (max-width: 990px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    .platform-card {
        min-height: 150px;
        // box-shadow: 2px 2px 4px rgba($color: #000000, $alpha: 0.2) !important;
    }
</style>